import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImageCards({image, handleEdit, handleRemove}) {
    return (
        <div className={styles.container}>
            <img alt='profil' src={image} className={styles.image} />
            <div className={styles.btnContainer}>
                    <button className={styles.btnEdit} title='Edit' onClick={() => handleEdit()}>
                        <FontAwesomeIcon icon='edit' />
                    </button>
                    <button className={styles.btnDelete} title='Delete' onClick={() => handleRemove()}>
                        <FontAwesomeIcon icon='trash' />
                    </button>
            </div>
        </div>
    )
}

const styles = {
    container:  'bg-white rounded relative flex m-4 justify-center',
    image: 'w-80 h-40 rounded object-fill',
    btnContainer: 'absolute inset-x-0 justify-between flex w-full bottom-0 p-3',
    btnEdit: 'p-2 bg-yellow-500 w-10 bg-opacity-90 active:bg-yellow-700 focus:outline-none rounded text-white text-sm',
    btnDelete: 'p-2 w-10 bg-opacity-90 bg-red-500 active:bg-red-700 focus:outline-none rounded text-white text-sm',
}
