import axios from 'axios';
import authHeader from './auth-header';
import AuthService from './auth.service';

// production
const BASE_URL = 'https://apiadmin.aqwam.com/api/';
// develoment
// const BASE_URL = 'https://apiadmin.aqwam.biz/api/';

const Get = (path, params) => {
    const api = axios.create({baseURL: BASE_URL});
    const promise = new Promise ((resolve, reject) =>{
        api.get(`${BASE_URL}${path}`, {params,  headers: authHeader() })
        .then((result) => {
            resolve(result.data);
            // console.log('cek result: ', result.data);
        }, (err) => {
            const response = err.response.data;
            if(response.message === 'Unauthenticated.'){
                AuthService.clearCookies();
            }
            reject(response);
        })
    })
    // console.log('cek promise : ', promise);
    return promise;
}

const Post = (path, params) => {
    const api = axios.create({baseURL: BASE_URL});
    const promise = new Promise ((resolve, reject) =>{
        api.post(`${BASE_URL}${path}`, params, { headers: authHeader() })
        .then((result) => {
            resolve(result.data);
        }, (err) => {
            const response = err.response.data;
            // console.log('message = ',err.response);
            if(response.message === 'Unauthenticated.'){
                AuthService.clearCookies();
            }
            reject(response);
        })
    })

    return promise;
}

/////////// GET DATA /////////////
const getDetailBook = (id) => Get(`getdetailbook?idBuku=${id}`);
const getListBook = (params) => Get(`getbook`, params);
const getSearchBook = (judul) => Get(`getbook?judul=${judul}`);
const getListCust = (params) => Get('getcust', params);
const getCustDetail = (id) => Get(`getdetailcust?idCust=${id}`);
const getPromo = (params) => Get('getpromo', params);
const getSearchPromo = (nama) => Get(`getpromo?namaPromo=${nama}`);
const getDetailPromo = (id) => Get(`getdetailpromo?codePromo=${id}`);
const getStorePromo = () => Get('showpromo');
const getListProduct = (params) => Get('getproduct', params);
const getDetailProduct = (id) => Get(`getdetailproduct?idProduct=${id}`);
const getDetailKeep = (id) => Get(`getdetailkeep?idProduct=${id}`);
const getCategoryBook = (params) => Get(`getkategori`, params);
const getDetailCategory = (id) => Get(`getdetailkategori?idBuku=${id}`);
const getCategoryPenerbit = () => Get(`getkategoripenerbit`);
const getDataCategory = () => Get(`getkategoribuku`);
const getBanner = () => Get(`getbanner`);
const getDetailBanner = (id) => Get(`getDetailBanner?idBanner=${id}`);
const getKeep = (params) => Get(`getkeep`, params);
const getpromokeep = () => Get(`getpromokeep`);

/////////// POST DATA //////////////
const addBook = (params) => Post('addbook', params);
const editBook = (params) => Post('editbook', params);
const addCoverBook = (params) => Post('addimagesingle', params);
const updateToggle = (id) => Post(`showstore?idBuku=${id}`);
const editCust = (params) => Post('editcust', params);
const resetPassCust = (id) => Post(`resetcust?idCust=${id}`);
const activePromo = () => Post('setshowpromo'); 
const addPromo = (params) => Post('addshowpromo', params);
const editPromo = (params) => Post('editshowpromo', params);
const softdeletePromo = (id) => Post(`softdelete?codePromo=${id}`);
const addCoverPromo = (params) => Post('addimagepromo', params);
const addProduct = (params) => Post('addproduct', params);
const editpo = (params) => Post('editpo', params);
const editJumlahKeep = (params) => Post(`editjumlahkeep`, params);
const deleteProduct = (id) => Post(`deleteproduct?idProduct=${id}`);
const editCategory = (params) => Post(`editkategori`, params);
const addBanner = (params) => Post(`addBanner`, params);
const editBanner = (params) => Post(`editBanner`, params);
const deleteBanner = (id) => Post(`deleteBanner?idBanner=${id}`);
const delpromokeep = (params) => Post(`delpromokeep`, params);

const API = {
    // Book
    getDetailBook,
    getListBook,
    getSearchBook,
    addBook,
    addCoverBook,
    editBook,
    updateToggle,
    //Customer
    getListCust,
    getCustDetail,
    editCust,
    resetPassCust,
    //Promo
    getPromo,
    getDetailPromo,
    getStorePromo,
    getSearchPromo,
    addPromo,
    softdeletePromo,
    editPromo,
    addCoverPromo,
    activePromo,
    //product
    getListProduct,
    getDetailProduct,
    getDetailKeep,
    addProduct,
    editpo,
    editJumlahKeep,
    deleteProduct,
    // Kategori
    getCategoryBook,
    getDetailCategory,
    getCategoryPenerbit,
    getDataCategory,
    editCategory,
    // Banner
    getBanner,
    addBanner,
    editBanner,
    getDetailBanner,
    deleteBanner,
    // Keep
    getKeep,
    getpromokeep,
    delpromokeep
}

export default API;