export default function validateBook(values) {
    let errors = {};
    
    if(!values.idBuku.trim()){
        errors.idBuku = 'Id buku belum diisi!'
    }
    
    if(!values.barcodeBuku.trim()){
        errors.barcodeBuku = 'Barcode belum diisi!'
    }
    
    if(!values.judulBuku.trim()){
        errors.judulBuku = 'Judul buku belum diisi!'
    }
    
    if(!values.hargaBuku.trim()){
        errors.hargaBuku = 'Harga buku belum diisi!'
    }
    
    if(!values.beratBuku.trim()){
        errors.beratBuku = 'Berat buku belum diisi!'
    }
    
    if(!values.isbnBuku.trim()){
        errors.isbnBuku = 'ISBN belum diisi!'
    }
    
    if(!values.penerbitBuku.trim()){
        errors.penerbitBuku = 'Penerbit belum diisi!'
    }
    
    if(!values.kategoriBuku.trim()){
        errors.kategoriBuku = 'Kategori buku belum diisi!'
    }
    
    if(!values.statusBuku.trim()){
        errors.statusBuku = 'Status buku belum diisi!'
    }
    
    if(!values.halamanBuku.trim()){
        errors.halamanBuku = 'Halaman buku belum diisi!'
    }
    
    if(!values.ukuranBuku.trim()){
        errors.ukuranBuku = 'Ukuran buku belum diisi!'
    }
    
    if(!values.coverBuku.trim()){
        errors.coverBuku = 'Jenis cover buku belum diisi!'
    }
    
    if(!values.tahunBuku.trim()){
        errors.tahunBuku = 'Tahun terbit belum diisi!'
    }
    
    if(!values.deskripsiBuku.trim()){
        errors.deskripsiBuku = 'Diskripsi buku belum diisi!'
    }

    return errors;
}
