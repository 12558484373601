const usePagination = (getParam, setParam) => {

    const handlePage = (e, type) => {
        e.preventDefault();
        const toPage = (getParam.offset + type); 
        setParam({
            ...getParam,
            offset : toPage
        })
    }

    const handleLimits = (e) => {
        e.preventDefault();
        setParam({
            ...getParam,
            limit: e.target.value
        })
    }

    return {handlePage, handleLimits}; 
}

export default usePagination;