import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ImgLogo} from '../../assets/img';
import UserDropdown from '../Dropdown/UserDropdown';
import {SidebarData} from '../Sidebar/SidebarData';
import MenuList from '../Sidebar/MenuList';

export default function Header({username, role}) {
    const [collapseShow, setCollapseShow] = useState(false);
    return (
        <nav className={styles.nav}>
            {/* Button menu */}
            <div className={styles.menuBtn}>
                <button type='button' onClick={() => setCollapseShow(true)} >
                    <FontAwesomeIcon icon='bars' size="lg" />
                </button>
            </div>
            {/* Header */}
            <div className={styles.logo}>
                <a href='#test' >
                    <img alt='profil' src={ImgLogo} className='h-10' /> 
                </a>       
            </div>
            <div className={styles.profile}>
                <div className={styles.userStatus}>
                    <h5 className='font-bold text-sm'>{username}</h5>
                    <h6 className='text-xs'>{role}</h6>
                </div>
                <UserDropdown size='w-10' icon />
            </div>
            {/* Collapse Menu */}
            <div className={styles.collapse(collapseShow)}>
                <div className={styles.cHeader}>
                    <div></div>
                    <div className={styles.cHeaderLabel}>
                        <span>Menu</span>
                    </div>
                    <div>
                        <button type='button' onClick={() => setCollapseShow(false)}>
                            <FontAwesomeIcon icon="times" size='lg' />
                        </button>
                    </div>
                </div>
                <hr className="my-4 min-w-full border-red-800" />
                <div className='px-3'>
                    {
                        SidebarData.map((item,idx) => {
                            return (
                                <MenuList
                                    key={idx}
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </nav>
    )
}

const styles ={
    nav: 'fixed bg-red-400 top-0 px-4 justify-between z-10 items-center left-0 h-20 md:h-14 flex w-full',
    menuBtn: 'md:hidden m-3',
    logo: 'flex items-center px-4',
    profile: 'flex space-x-3 items-center',
    userStatus:'md:block hidden text-center text-white',
    collapse: (show) => ((show ? 'bg-white m-2 py-3 px-6' : 'hidden') + ' md:hidden md:w-full md:flex-col md:p-0 md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 h-auto items-center flex-1 rounded'),
    cHeader: 'flex w-full justify-between items-center text-red-400',
    cHeaderLabel : 'text-center uppercase font-bold text-xl'
}