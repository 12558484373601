import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Input } from '../../components';
import APIFile from '../../services/files.service';
import API from '../../services/data.service';
import Utils from '../../utils/';
import Alert from '../Alerts';
import { useDispatch } from 'react-redux';

export default function UploadModals({handleShow, data, title, page}) {
    const [files, setFiles] = useState(null);
    const [params, setParams] = useState(null);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const BASE_URL = 'https://admin.aqwam.com/';
    const [image, setImage] = useState(data ? `${BASE_URL}${data.gambarBuku}` : null);

    const onChange = (e) => {
        const file = e.target.files[0];
        const getFormat = file.name.split(/\.(?=[^\.]+$)/);
        const format = getFormat[getFormat.length -1];
        setFiles(file);
        setImage(URL.createObjectURL(file));
        const curDate = Utils.formatDate( Date.now() ,'imageName');
        const nama = data ? `storage/${curDate}_${data.id}.${format}` : `storage/${curDate}.${format}`;
        // console.log('cek anam = ', nama, page);
        setPostData(page, nama);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await APIFile.uploadServer(params.gambarBuku,files);
            await setApi(page, params);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            handleShow();
            window.location.reload();
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();            
            setErrors({failed : error.status.description});
        } 
    }


    const setPostData = (param, nama) => {
        switch (param) {
            case 'promo':
                return setParams({codePromo: data.id, gambarBuku: nama});
            case 'book':
                return setParams({idBuku: data.id, gambarBuku: nama});
            case 'banner':
                console.log('cek data ', data);
                console.log('cek nama ', nama);
                return data ? setParams({idBanner: data.id, gambar: nama}) : setParams({gambarBuku: nama});
            default:
                return;
        }
    }

    console.log('cek params = ', params);

    const setApi = async (page, params) => {
        switch (page) {
            case 'promo':
                return await API.addCoverPromo(params);
            case 'book':
                return await API.addCoverBook(params);
            case 'banner':
                return data ? await API.editBanner(params) : await API.addBanner(params);
            default:
                return;
        }
    }

    // console.log('data = ', params);

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <div className={styles.formContainer}>
                                { image &&
                                    <div className='p-2 bg-gray-200 flex justify-center w-auto'>
                                        <img alt='preview' src={image} className='object-contain h-64 rounded' />
                                    </div>
                                }
                                <Input type='files' label='Set Cover' onSubmit={(e) => onSubmit(e)} onChange={(e) => onChange(e)} error={errors.failed} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white overflow-y-scroll w-3/4 h-5/6 rounded p-6',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col relative container justify-center bg-gray-100 space-y-2 h-auto p-4',
    cDoubleRows: 'flex space-x-4',
    cHalfRows: 'flex flex-col w-1/2',
    gallery : 'w-full h-auto bg-gray-300 p-4 flex flex flex-wrap',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0',
}
