import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import API from '../../../services/data.service';
import Utils from '../../../utils';
import PromoModal from './PromoModal';
import Swal from 'sweetalert2';
import Alert from '../../../components/Alerts';
import HeaderContent from '../../../components/Headers/HeaderContent';
import FooterContent from '../../../components/Footers/FooterContent';
// import DataGalery from '../Books/DataGalery';
import UploadModals from '../../../components/Uploader/UploadModals';
import {usePagination} from '../../../utils/useForm';

export default function Promo() {
    const [searchValue, setSearchValue] = useState('');
    const [paramsGet, setParamsGet] = useState({namaPromo: searchValue, offset: 1, limit: 10});
    const [paramsImage, setParamsImage] = useState({id:'', gambarBuku:'',});
    const [listPromo, setListPromo] = useState(null);
    const [showStore, setShowStore] = useState(false);
    const [showAddPromo, setShowAddPromo] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showGalery, setShowGalery] = useState(false);
    const [promoDetail, setPromoDetail] = useState(null);
    const dispatch = useDispatch();
    const storePromoStatus = Utils.formatStatus(showStore);
    const {handlePage, handleLimits} = usePagination(paramsGet, setParamsGet);
    const [dataCount, setDataCount] = useState(0);

    /// Search /////
    const onSearchSubmit = async (e) => {
        e.preventDefault();
        setParamsGet({
            ...paramsGet,
            namaPromo: searchValue
        })
        setSearchValue('');
    }

    /////////// Content ///////
    const updateStorePromo = async () => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: `Data akan mengupdate store promo!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Aktifkan!'
          }).then((result) => {
            if (result.isConfirmed) {
                updateStore();
            }
          })
        }
        
    const updateStore = async () => {
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await API.activePromo();
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            window.location.reload(false);
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    }

    const handleEditClick = async (id) => {
        dispatch({type: 'SET_LOADING', value: true});
        try {
            const data = await API.getDetailPromo(id);
            setPromoDetail(data.data);
            dispatch({type: 'SET_LOADING', value: false});
            setShowEdit(true);
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
        }
    }
    
    const handleSoftDelete = async (id) => {
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await API.softdeletePromo(id);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            window.location.reload();
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    }

    useEffect(() => {
        const fetchListPromo = async (paramsGet) => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getPromo(paramsGet);
                setDataCount(data.count);
                setListPromo(data.data);
                const storePromo = await API.getStorePromo();
                setShowStore(storePromo.data.type);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                if(paramsGet.namaPromo !== ''){
                    Alert.notFound();
                }else{
                    Alert.error();
                }
            }
        }
        fetchListPromo(paramsGet);
    }, [paramsGet, dispatch])

    const handleGalery = async (id, image) => {
        dispatch({type: 'SET_LOADING', value: true});
        setParamsImage({
            id: id, 
            gambarBuku: image
        });
        dispatch({type: 'SET_LOADING', value: false});
        setShowGalery(true);
    }

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Data Promo</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Data / </span>
                    <strong>Promo</strong>
                </div>

                <div className={styles.content}>
                    <div className={styles.cHeader}>
                        <span className={styles.cTitle}>Tampilkan Semua Promo Di Store</span>
                        <button className={styles.btnShowStore(storePromoStatus)} onClick={updateStorePromo}>
                            {storePromoStatus ? <span>Active</span> : <span>Not Active</span>}
                        </button>
                    </div>
                </div>

                <div className={styles.content}>
                    <HeaderContent 
                        title='Data Promo'
                        showModal={() => setShowAddPromo(true)}
                        searchValue={searchValue}
                        handleLimits={(e) => handleLimits(e)}
                        onChangetext={(value) => setSearchValue(value)}
                        onSearchSubmit={(value) => onSearchSubmit(value)}
                    />
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Promo</th>
                                    <th>Pre Order</th>
                                    <th>Tanggal Cetak</th>
                                    <th>Gambar Buku</th>
                                    <th>Soft Delete</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {listPromo &&
                                    listPromo.map((item, idx) => {
                                        return(
                                            <tr key={idx} className={styles.row}>
                                                <td className='pr-6'>{(idx + 1)}</td>
                                                <td className='text-left'>{item.namaPromo}</td>
                                                <td className=''>
                                                    {Utils.formatStatus(item.jenisPO) ? 'Yes' : 'No'}
                                                </td>
                                                <td className='text-left'>{Utils.formatDate(item.tanggalCetak)}</td>
                                                <td className='text-left'>{item.gambarBuku && Utils.validateImagePath(item.gambarBuku)}</td>
                                                <td className=''>
                                                    <button className={styles.btnDelete(Utils.formatStatus(item.aktif))} title='Soft Delete' onClick={() => handleSoftDelete(item.codePromo)}>
                                                        {Utils.formatStatus(item.aktif) ? 'Yes' : 'No'}
                                                    </button>
                                                </td>
                                                <td className='space-x-2 text-white'>
                                                    <button className={styles.btnEdit} title='Edit' onClick={() => handleEditClick(item.codePromo)}>
                                                        <FontAwesomeIcon icon='edit' />
                                                    </button>
                                                    <button className={styles.btnShowPicture} title='Show Picture' onClick={() => handleGalery(item.codePromo, item.gambarBuku)}>
                                                        <FontAwesomeIcon icon='image' />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <FooterContent page={paramsGet.offset} jumlahData={dataCount} limit={paramsGet.limit} setPage={(e, type) => handlePage(e, type)} />
                </div>
            </div>
            {showAddPromo && <PromoModal title='Tambah Promo' handleShow={() => setShowAddPromo(false)} />}
            {showEdit && <PromoModal title='Edit Promo' handleShow={() => setShowEdit(false)} edit data={promoDetail} />}
            {showGalery && <UploadModals handleShow={() => setShowGalery(false)} title='Galeri Buku' data={paramsImage} page='promo' />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    btnShowStore: (active) => ((active ? 'bg-green-500 active:bg-green-700' : 'bg-red-500 active:bg-red-700') + ' text-white mr-4 p-2 space-x-2 focus:outline-none rounded w-32'),
    btnAdd: 'bg-green-500 active:bg-green-700 text-white mr-4 p-2 space-x-2 focus:outline-none rounded',
    tableContainer: 'py-4 px-6',
    table: 'table-auto w-full text-center',
    row: ' items-center border-t cursor-pointer hover:bg-gray-100',
    btnDelete: (active) => ((active ? 'bg-green-400 hover:bg-green-600' : 'bg-red-400 hover:bg-red-600') + ' px-2 text-white py-0.5 focus:outline-none rounded text-sm w-10'),
    btnShowPicture: 'p-2 bg-green-500 active:bg-green-700 focus:outline-none rounded text-sm',
    btnEdit: 'p-2 bg-yellow-500 active:bg-yellow-700 focus:outline-none rounded text-sm',
}
