import React from 'react';
import Header from '../components/Headers/Header';
import SideBar from '../components/Sidebar/Sidebar';
import { Switch, Route} from "react-router-dom";
import Utils from '../utils';
import cookie from 'react-cookies';

import NotFound from '../layout/NotFound';
import Welcome from '../pages/Welcome';
///////// Master Data ////////////////
import Books from '../pages/Data/Books';
import Customers from '../pages/Data/Customers';
import Promo from '../pages/Data/Promo';
import HomeProduct from '../pages/Data/HomeProduct';
import CategoryBooks from '../pages/Data/Category';
import Keep from '../pages/Data/Keep';

///////// Utility ////////////////
import SlideBanner from '../pages/Utility/SlideBanner';

/////// About Us /////////////
import About from '../pages/About/About';

export default function Home() {
    const username = cookie.load('nama');
    const role = Utils.formatRole(cookie.load('jabatan'));
    return (
        <>
            <SideBar />
            <div className='relative md:ml-64 md:mt-14 min-h-screen h-auto bg-gray-200'>
                <Header username={username} role={role} />
                <Switch>
                    <Route path="/" exact component={Welcome} />
                    <Route path="/data/buku" exact component={Books} />
                    <Route path="/data/customer" exact component={Customers} />
                    <Route path="/data/promo" exact component={Promo} />
                    <Route path="/data/homeProduct" exact component={HomeProduct} />
                    <Route path="/data/categoryBook" exact component={CategoryBooks} />
                    <Route path="/data/keep" exact component={Keep} />
                    <Route path="/utility/banner" exact component={SlideBanner} />
                    <Route path="/about/about" exact component={About} />
                    <Route component={NotFound} />
                </Switch>
            </div>   
        </>
    )
}
