import Swal from 'sweetalert2';

const error = async (desc) => {
    await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: desc ? desc : 'Something went wrong!',
        timer: 1500
    });
}

const success = async () => {
    await Swal.fire({
        position:'center',
        icon: 'success',
        title: 'Succesfully',
        showConfirmButton: false,
        timer: 1500
    })
}

const notFound = () => {
    Swal.fire(
        'Data tidak ditemukan?',
        'data yang anda cari tidak ditemukan?',
        'error'
      )
}

const Alert = {
    error,
    success,
    notFound
}

export default Alert;