import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import Alert from '../../../components/Alerts';
import Input from '../../../components/InputForm/';
import API from '../../../services/data.service';
import {useForm} from '../../../utils/useForm';
import validate from '../../../utils/validate/validateCust';

export default function CustModal({title, handleShow, data}) {
    const {handleChange, values} = useForm(data);
    const jenisCust = [{value: 0, label:'Customer'},{value:1, label:'Reseller'}];
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setSubmiting(true);
    };

    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                await API.editCust(values)
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload();
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.error();
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [errors, submiting, dispatch, handleShow, values])

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.formContainer}>
                                    <Input label='ID Customer' type='text' name="idCust" value={values.idCust} onChange={handleChange} error={errors.idCust} />
                                    <Input label='Nama Pelanggan' type='text' name="nama" value={values.nama} onChange={handleChange} error={errors.nama} />
                                    <Input label='Alamat' type='text' name="alamat" value={values.alamat} onChange={handleChange} error={errors.alamat} />
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input 
                                                type='select' 
                                                label='Jenis Pelanggan'
                                                name='jenisCust'
                                                value={values.jenisCust} onChange={handleChange}
                                                data={jenisCust}
                                                error={errors.jenisCust}
                                            />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='No. Telepon' type='number' name="telephone" value={values.telephone} onChange={handleChange} error={errors.telephone} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-2',
    cDoubleRows: 'flex space-x-4',
    cHalfRows: 'flex flex-col w-1/2',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0'
}
