export default function validateCategory(values) {
    let errors = {};
    
    if(values.penerbit === '-'){
        errors.penerbit = 'Penerbit wajib diisi!'
    }

    if(values.kategori.length === 0){
        errors.kategori = 'Kategori wajib diisi!'
    }

    return errors;
}
