export default function validateProduct(values, isPO) {
    let errors = {};
    
    if(values.judulBuku.length === 0){
        errors.judulBuku = (isPO ? 'Nama promo wajib diisi!' : 'Judul buku wajib diisi!')
    }

    if(!values.jumlahKeep.trim()){
        errors.jumlahKeep = 'Jumlah Keep belum diisi!'
    }

    return errors;
}
