import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function SelectMultiple(passProps) {
    const [handleShow, setHandleShow] = useState(false)
    const props = passProps.props;

    const handleSelectShow = (e) => {
        e.preventDefault();
        setHandleShow(!handleShow);
    }

    const handleSelect = (code, nama) =>{
        setHandleShow(false);
        props.handleSelect(code, nama);
    }

    return(
        <>
            <div className={styles.container}>
                <label className={styles.label}>{props.label}</label>
                <div className={styles.selectBox}>
                    <button name='kategori' className={styles.btnSelect} onClick={handleSelectShow}>
                        <span>{props.title}</span>
                        <FontAwesomeIcon icon='chevron-down' className='text-xs' />
                    </button>
                    <div className={styles.itemData(handleShow)}>
                        {props.data && props.data.map((item, idx) => {
                            return(
                                <li key={idx} 
                                value={item.codeKategori} 
                                className={styles.listData} 
                                onClick={() => handleSelect(item.codeKategori, item.namaKategori)}>
                                    {item.namaKategori}
                                </li>
                            )
                        })}
                    </div>
                </div>
                <div className='space-y-1 mt-2 mx-4 space-x-2 flex flex-wrap items-center'>
                    {props.selected &&  props.selected.map((data, idx) => {
                        return (
                            <div className={styles.selectedContainer} key={idx}>
                                <span>{data.namaKategori}</span>
                                <button type='button' className={styles.btnRemove} title='Close' onClick={() => props.onRemoveList(idx)} >
                                    <FontAwesomeIcon icon="times" size='lg' />
                                </button>
                            </div>
                        )
                    })}
                </div>
                {props.error && <span className={styles.error}>{props.error}</span>}
            </div>
        </>
    )
}

const styles = {
    container: 'w-full flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    selectBox: 'w-full container relative mx-auto',
    btnSelect: 'ring-2 w-full ring-gray-300 rounded bg-white text-left py-1 px-2 my-1 flex justify-between items-center focus:outline-none focus:ring focus:ring-blue-300',
    itemData: (show) => ((show ? 'block ' : 'hidden') 
    + ' block absolute z-50 bg-white text-gray-700 overflow-y-scroll h-40 p-2 w-full list-none text-left rounded shadow-lg'),
    listData: 'cursor-pointer hover:bg-gray-200',
    selectedContainer: 'inline-flex w-auto h-auto ring-1 ring-gray-100 bg-white rounded-full cursor-pointer hover:bg-gray-50 space-x-4 px-3 py-1 flex justify-between items-center',
    btnRemove: 'focus:outline-none mr-2 text-red-500',
    error:'text-red-400 text-left text-sm'
}
