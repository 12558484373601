const validateImagePath = (path) => {
    const validate = path.includes('no-image');
    return validate ? '-- No Image --' : path;
}

const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
}

const formatStatus = (params) => {
    if (typeof params === 'string' || params instanceof String){
        return params === '1' || params === 'Y' ? true : false;
    }
    else{
        return params = 0 ? false : true;
    }
}

const formatDate = (params, mode) => {
    let date = new Date(params);
    let dd = String(date.getDate()).padStart(2, '0');
    let MM = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    let yy = yyyy.toString().substr(-2);
    let hh = date.getHours();
    let mm = date.getMinutes();
    let ss = date.getSeconds();
    let ms = date.getMilliseconds();

    switch (mode) {
        case 'mmddYYYY':
            return`${MM}/${dd}/${yyyy}`;
        case 'YYYYmmdd':
            return `${yyyy}-${MM}-${dd}`;
        case 'imageName':
            return `${yy}${MM}${dd}_${hh}${mm}${ss}${ms}`;
        default:
            return`${MM}/${dd}/${yyyy}`;
    }
}

const formatPO = (params) => {
    switch (params) {
        case 'PO':
            return 'Pre Order';
        case 'new':
            return 'New Product';
        case 'best':
            return 'Best Seller';
        case '0' || 0:
            return 'Normal';
        case '1' || 1:
            return 'Sold Out';
        case '2' || 2:
            return 'Comming Soon';
        case '3' || 3:
            return 'Promo Berakhir';
        default:
            return 'Pre Order';
    }
}

const formatRole = (params) => {
    switch (params) {
        case 1:
            return 'Super User';
        case 2:
            return 'Admin';
        default:
            return 'Admin';
    }
}

const Utils = {
    validateImagePath,
    formatRupiah,
    formatDate,
    formatStatus,
    formatPO,
    formatRole
}


export default Utils;