import {useState, useEffect} from 'react';
import API from '../../services/data.service';

const SearchForm = (typeAPI, isPO) => {
    const [searchValues, setSearchValues] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [isSearch, setSearch] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const handleSeacrhChange =(e) =>{
        const val = e.target.value;
        setSearchValues(val);
        val.length > 1 ? setSearch(true) : setSearch(false);
    }

    useEffect(() => {
        const setAPI = async (value) => {
            if(typeAPI === 'book'){
                return API.getSearchBook(value);
            }
            else if(typeAPI === 'product'){
                return isPO ? API.getSearchPromo(value) : API.getSearchBook(value);
            }
        }
        const fetchSearch = async (judul) => {
            try {
                setNotFound(false);
                setSearchLoad(true);
                const data = await setAPI(judul);
                setSearchData(data.data);
                setSearchLoad(false);
            } catch (error) {
                setNotFound(true);
            }
        }
        fetchSearch(searchValues);
    }, [searchValues, isPO, typeAPI]);

    return {
        searchValues,
        searchLoad,
        isSearch,
        searchData,
        notFound,
        setSearchValues,
        setSearch,
        handleSeacrhChange,
    };
}

export default SearchForm;