import {useState} from 'react';

const useForm = (data) => {
    const [values, setValues] = useState(data)

    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name] : value
        })
    }

    const customChange = (e, editor, dataName) => {
        editor ? 
        setValues({
            ...values, 
            [dataName]: editor.getData()
        })
        :
        setValues({
            ...values,
            [e.target.name] :  e.target.value
        })
    }

    return {handleChange, customChange, values, setValues}; 
}

export default useForm;