import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import {Input} from '../../../components';
import validate from '../../../utils/validate/validateEditProduct';
import API from '../../../services/data.service';
import Alert from '../../../components/Alerts';

export default function EditProduct({title, data, handleShow}) {
    const [jumlahValue, setJumlahValue] = useState({codePromo: data.codePromo, jumlah: data.jumlahSistem});
    const [typeValue, setTypeValue] = useState({codePromo: data.codePromo, typePO: data.typePO});
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const dispatch = useDispatch();
    const normal = data.typePO === '0' ? true : false;
    const typeNormal = [{value:0, label:'Normal'},{value:1, label:'Sold Out'},{value:2, label:'Coming Soon'},{value:3, label:'Promo Berakhir'}];
    const typePO = [{value:1, label:'Sold Out'},{value:2, label:'Coming Soon'},{value:3, label:'Promo Berakhir'}];

    const handleJumlahChange = (e) => {
        const {name, value} = e.target;
        setJumlahValue({
            ...jumlahValue,
            [name] : value
        })
    }
    
    const handlePOChange = (e) => {
        const {name, value} = e.target;
        setTypeValue({
            ...typeValue,
            [name] : value
        })
    }
    
    const onJumlahSubmit = (e) => {
        e.preventDefault();
        setErrors(validate(jumlahValue));
        setSubmiting(true);
    }

    const onPOSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch({type: 'SET_LOADING', value: true});
            await API.editpo(typeValue);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            handleShow();
            window.location.reload(false);
        } catch (err) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    }
    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                await API.editJumlahKeep(jumlahValue);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload(false);
            } catch (err) {
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.error();
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [errors, submiting, dispatch, jumlahValue, handleShow])

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            {normal  &&
                                <form onSubmit={onJumlahSubmit}>
                                    <div className={styles.formContainer}>
                                        <div>
                                            <h1 className={styles.title}>{data.namaPromo}</h1>
                                            <div className={styles.keep}>
                                                <span>Jumlah Keep System : {data.jumlahSistem}</span>
                                                <span>Jumlah Keep Reseller : {data.jumlahKeep}</span>
                                            </div>
                                        </div>
                                        <Input label='Update Jumlah Keep' type='number' name="jumlah" value={jumlahValue.jumlah} onChange={handleJumlahChange} error={errors.jumlah} />
                                        <div className='flex justify-end'>
                                            <input className={styles.btnSubmit} value='Update' type="submit"/>
                                        </div>
                                    </div>
                                </form>
                            }
                            <form onSubmit={onPOSubmit}>
                                <div className={styles.formContainer}>
                                    <Input type='select' label='Tipe Pre Order' name='typePO' value={typeValue.typePO} onChange={handlePOChange} data={normal ? typeNormal : typePO} />
                                    <div className='flex justify-end'>
                                        <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-4',
    title: 'text-center text-gray-700',
    keep: 'my-4 px-8 flex justify-between',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0'
}
