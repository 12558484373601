import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {Alert, Input} from '../../../components';
import API from '../../../services/data.service';
import {useForm} from '../../../utils/useForm';
import validate from '../../../utils/validate/validateCategory';

export default function CategoryModals({handleShow, id, title}) {
    const {values, setValues} = useForm(null);
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const [dataPenerbit, setDataPenerbit] = useState(null);
    const [dataCategory, setDataCategory] = useState(null);
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setSubmiting(true);
    };

    const handleChange = (e) => {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        setValues({
            ...values,
            penerbit: label,
            codePenerbit: e.target.value
        });
    }

    const handleRemoveKategori = (idx) => {
        values.kategori.splice(idx, 1);
        setValues({
            ...values,
            kategori: values.kategori
        })
    }

    const handleSelect = (code, nama) => {
        let valid = true;
        values.kategori.forEach(val => {
            if(val.codeKategori === code){
                valid = false;
            }
        });
        if(valid){
            setValues({
                ...values,
                kategori: [...values.kategori, {codeKategori: code, namaKategori: nama}]
            })
        }
    }

    useEffect(() => {
        const fetchData = async (id) => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                const data = await API.getDetailCategory(id);
                const penerbit = await API.getCategoryPenerbit();
                const kategori = await API.getDataCategory();
                setValues(data.data);
                setDataPenerbit(penerbit.data);
                setDataCategory(kategori.data);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (err) {
                dispatch({type: 'SET_LOADING', value: false});
                console.error(err);
                Alert.error();
            }
        }
        fetchData(id);
    },[])

    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                await API.editCategory(values);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload();
            } catch (err) {
                dispatch({type: 'SET_LOADING', value: false});
                console.error(err);
                await Alert.error();
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [errors, submiting, dispatch, handleShow, values])

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        {values &&
                            <div className={styles.modalContent}>
                                <form onSubmit={onSubmit}>
                                    <div className={styles.formContainer}>
                                        <Input label='Judul Buku' type='text' name="judulBuku" value={values.judulBuku} readOnly/>
                                        <div className={styles.container}>
                                            <label className={styles.label}>Penerbit</label>
                                            <select value={values.codePenerbit} onChange={handleChange}>
                                                {values.penerbit === '-' && <option value='-'>-</option>}
                                                {dataPenerbit && dataPenerbit.map((data, idx) => {
                                                    return (
                                                        <option key={idx} value={data.codePenerbit}>{data.namaPenerbit}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors.penerbit && <span className={styles.error}>{errors.penerbit}</span>}
                                        </div>
                                        <Input type='multiple-select'
                                            label='Kategori'
                                            title='--Pilih Kategori--' 
                                            data={dataCategory} 
                                            handleSelect={(code, nama) => handleSelect(code, nama)} 
                                            selected={values.kategori}
                                            onRemoveList={(idx) => handleRemoveKategori(idx)} 
                                            error={errors.kategori}
                                        />
                                    </div>
                                    <div className='flex justify-end'>
                                        <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-4',
    container: 'flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    error:'text-red-400 text-left text-sm',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0',
    btnKategoriDel: 'focus:outline-none mr-2 text-red-500',
}
