import axios from 'axios';

const FILE_URL = 'https://admin.aqwam.com/upload.php';
const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
}

const Post = (nama, files) => {
    console.log('cek anam masuk = ', nama);
    const formData = new FormData();
    formData.append('files',files)
    const api = axios.create({baseURL: FILE_URL});
    const promise = new Promise ((resolve, reject) =>{
        api.post(`${FILE_URL}${nama}`, formData, { headers })
        .then((result) => {
            resolve(result.data);
        }, (err) => {
            const response = err.response.data.data;
            reject(response);
        })
    })

    return promise;
}

const uploadServer = (nama, files) => Post(`?nama=${nama}`,files);

const APIFile = {
    uploadServer,
}

export default APIFile;