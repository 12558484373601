import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import HeaderContent from '../../components/Headers/HeaderContent';
import { useDispatch } from 'react-redux';
import API from '../../services/data.service';
import ImageCards from '../../components/Cards/ImageCards';
import Swal from 'sweetalert2';
import Alert from '../../components/Alerts';
import UploadModals from '../../components/Uploader/UploadModals';

export default function SlideBanner() {
    const [dataList, setDataList] = useState([]);
    const dispatch = useDispatch();
    const [paramsImage, setParamsImage] = useState(null);
    const [showUpload, setShowUpload] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const BASE_URL = 'https://admin.aqwam.com/';


    useEffect(() => {
        const fetchData = async () => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getBanner();
                setDataList(data.data);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                Alert.error();
            }
        }
        fetchData();
    }, [dispatch])

    const handleEdit = async (id, image) => {
        dispatch({type: 'SET_LOADING', value: true});
        setParamsImage({
            id: id, 
            gambarBuku: image
        });
        dispatch({type: 'SET_LOADING', value: false});
        setShowEdit(true);
    }

    const handleRemove = (id) => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: `Data akan dihapus!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus data!'
          }).then((result) => {
            if (result.isConfirmed) {
              deleteBanner(id)
            }
          })
    }

    const deleteBanner = async (id)=>{
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await API.deleteBanner(id);
            dispatch({type: 'SET_LOADING', value: false});
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            window.location.reload(false);
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            Alert.error();
        }
    } 

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Data Slide Banner</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Utility / </span>
                    <strong>Banner</strong>
                </div>
                <div className={styles.content}>
                    <HeaderContent 
                        title='Data Slide Banner'
                        showModal={() => setShowUpload(true)}
                    />
                    <div className={styles.tableContainer}>
                        <div className={styles.formContainer}>
                            {dataList &&
                                dataList.map((item, idx) => {
                                    return(
                                        <ImageCards 
                                            key={idx} 
                                            image={`${BASE_URL}${item.gambar}`}
                                            handleEdit={() => handleEdit(item.idBanner, item.gambar)}
                                            handleRemove={() => handleRemove(item.idBanner)} 
                                        />
                                    )
                                })
                            }
                        </div>                            
                    </div>
                </div>
            </div>
            {showUpload && <UploadModals handleShow={() => setShowUpload(false)} title='Banner Galery' page='banner' />}
            {showEdit && <UploadModals handleShow={() => setShowEdit(false)} title='Banner Galery' data={paramsImage} page='banner' />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    tableContainer: 'py-4 px-6 w-full',
    formContainer: 'flex flex-wrap relative container bg-gray-200 space-x-4 h-auto p-4',
    image: 'w-42 rounded object-none',
    btnEdit: 'p-2 bg-yellow-500 w-10 bg-opacity-30 active:bg-yellow-700 focus:outline-none rounded text-white text-sm',
    btnDelete: 'p-2 w-10 bg-opacity-30 bg-red-500 active:bg-red-700 focus:outline-none rounded text-white text-sm',
}