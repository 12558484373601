import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import API from '../../../services/data.service';
import Utils from '../../../utils';
import DataBookModal from './DataBookModal';
import Alert from '../../../components/Alerts';
import HeaderContent from '../../../components/Headers/HeaderContent';
import FooterContent from '../../../components/Footers/FooterContent';
import UploadModals from '../../../components/Uploader/UploadModals';
import {usePagination} from '../../../utils/useForm';

export default function Books() {
    const [searchValue, setSearchValue] = useState('');
    const [paramsGet, setParamsGet] = useState({offset: 1, judul: searchValue, limit: 10});
    const [paramsImage, setParamsImage] = useState({id:'', gambarBuku:''});
    const [dataCount, setDataCount] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [showInsert, setShowInsert] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [dataDetail, setDataDetail] = useState(null);
    const {handlePage, handleLimits} = usePagination(paramsGet, setParamsGet);
    const dispatch = useDispatch();

    //// Search //////
    const onSearchSubmit = (e) => {
        e.preventDefault();
        setParamsGet({
            ...paramsGet,
            judul: searchValue
        })
        setSearchValue('');
    }

    //////// Content //////
    const handleEditClick = async (id) => {
        dispatch({type: 'SET_LOADING', value: true});
        const data = await API.getDetailBook(id);
        setDataDetail(data.data);
        dispatch({type: 'SET_LOADING', value: false});
        setShowEdit(true);
    }

    const handleToogleChange = async (id) => {
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await API.updateToggle(id);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            window.location.reload();
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    } 

    useEffect(() => {
        const fetchData = async (paramsGet) => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getListBook(paramsGet);
                setDataList(data.data);
                setDataCount(data.count);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                if(paramsGet.judul !== ''){
                    Alert.notFound();
                }else{
                    Alert.error();
                }
            }
        }
        fetchData(paramsGet);
    }, [paramsGet, dispatch])

    const handleUpload = async (id, image) => {
        dispatch({type: 'SET_LOADING', value: true});
        setParamsImage({
            id: id, 
            gambarBuku: image
        });
        dispatch({type: 'SET_LOADING', value: false});
        setShowUpload(true);
    }

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Data Buku</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Data / </span>
                    <strong>Buku</strong>
                </div>
                <div className={styles.content}>
                    <HeaderContent 
                        title='Data Buku'
                        showModal={() => setShowInsert(true)}
                        searchValue={searchValue}
                        handleLimits={(e) => handleLimits(e)}
                        onChangetext={(value) => setSearchValue(value)}
                        onSearchSubmit={(value) => onSearchSubmit(value)}
                    />
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Id buku</th>
                                    <th>Judul Buku</th>
                                    <th>Harga</th>
                                    <th>Gambar Buku</th>
                                    <th>Tampilkan Store</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {dataList &&
                                    dataList.map((item, idx) => {
                                        return(
                                            <tr key={idx} className={styles.row}>
                                                <td>{(idx + 1)}</td>
                                                <td>{item.idBuku}</td>
                                                <td className='text-left'>{item.judulBuku}</td>
                                                <td className='text-left'>{item.hargaBuku && Utils.formatRupiah(item.hargaBuku)}</td>
                                                <td className='text-left pl-6'>{item.gambarBuku && Utils.validateImagePath(item.gambarBuku)}</td>
                                                <td className=''>
                                                    <button 
                                                        className={styles.btnShowStore(Utils.formatStatus(item.showStore))} 
                                                        title='Edit' 
                                                        onClick={() => handleToogleChange(item.idBuku)}>
                                                        {
                                                            Utils.formatStatus(item.showStore) ? <span>Yes</span> : <span>No</span>
                                                        }
                                                    </button>
                                                </td>
                                                <td className='space-x-2 w-40 text-white'>
                                                    <button className={styles.btnEdit} title='Edit' onClick={() => handleEditClick(item.idBuku)}>
                                                        <FontAwesomeIcon icon='edit' />
                                                    </button>
                                                    <button className={styles.btnShowPicture} title='Show Picture' onClick={() => handleUpload(item.idBuku, item.gambarBuku)}>
                                                        <FontAwesomeIcon icon='image' />
                                                    </button>
                                                    <button className={styles.btnDelete} title='Delete'>
                                                        <FontAwesomeIcon icon='trash' />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <FooterContent page={paramsGet.offset} jumlahData={dataCount} limit={paramsGet.limit} setPage={(e, type) => handlePage(e, type)}  />
                </div>
            </div>
            {showInsert && <DataBookModal handleShow={() => setShowInsert(false)} title='Tambah Data Buku' />}
            {showEdit && <DataBookModal edit handleShow={() => setShowEdit(false)} data={dataDetail} title='Edit Data Buku' />}
            {showUpload && <UploadModals handleShow={() => setShowUpload(false)} title='Cover Buku' data={paramsImage} page='book' />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    btnAdd: 'bg-green-500 active:bg-green-700 text-white mr-4 p-2 space-x-2 focus:outline-none rounded',
    tableContainer: 'py-4 px-6',
    table: 'table-auto w-full text-center',
    row: ' items-center border-t cursor-pointer hover:bg-gray-100',
    btnShowStore: (active) => ((active ? 'bg-green-400 hover:bg-green-600' : 'bg-red-400 hover:bg-red-600') + ' px-2 text-white py-0.5 focus:outline-none rounded text-sm w-10'),
    btnEdit: 'p-2 bg-yellow-500 active:bg-yellow-700 focus:outline-none rounded text-sm',
    btnShowPicture: 'p-2 bg-green-500 active:bg-green-700 focus:outline-none rounded text-sm',
    btnShowGallery: 'p-2 bg-blue-500 active:bg-blue-700 focus:outline-none rounded text-sm',
    btnDelete: 'p-2 bg-red-500 active:bg-red-700 focus:outline-none rounded text-sm',
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 z-30 bg-gray-50'
}
