import React from 'react';

export default function SendFiles(data) {
    const props = data.props;

    return (
        <form onSubmit={(e) => props.onSubmit(e)}>
            <div className='w-full p-2'>  
                {props.error && <span className={styles.error}>{props.error}</span>}
                <label className={styles.label}>{props.label}</label>
                <div className={styles.inputBox}>
                    <input type='file' className='w-full' onChange={(e) => props.onChange(e)} accept='image/*'/>
                    <button type='submit' className={styles.btnUpload} title='Upload' >
                        <span>Upload</span>
                    </button>
                </div>
            </div>
        </form>
    )
}

const styles ={
    container: 'bg-green-400',
    label: 'text-gray-700 font-semibold',
    inputBox: 'w-full flex justify-between items-center',
    btnUpload: 'p-2 bg-blue-500 active:bg-blue-700 w-16 text-white m-2 focus:outline-none rounded text-sm',
    error:'text-red-400 text-sm justify-center flex',
}
