import {createStore} from 'redux';

const initialState = {
    loading: false,
    login: true
};

const reducer = (state = initialState, action) => {

    switch(action.type){
        case 'SET_LOADING':
            return{
                ...state,
                loading: action.value,
            }
        case 'SET_LOGIN':
            return{
                ...state,
                login: action.value,
            }
        default:
            return state;
    }
}

const store = createStore(reducer);

export default store;