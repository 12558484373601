import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function TextEditor(props) {
    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>{props.props.label}</label>
                <CKEditor 
                    editor={ ClassicEditor } 
                    config={ {
                        toolbar: {
                            items: [ 'bold', 'italic', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList' ]
                        }
                    } }
                    data={props.props.value}
                    onChange={(e, editor) => props.props.onChange(e, editor)}
                />
                {props.props.error && <span className={styles.error}>{props.props.error}</span>}
            </div>
        </>
    )
}

const styles = {
    container: 'flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    error:'text-red-400 text-left text-sm'
}