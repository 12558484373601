import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {Alert, Input} from '../../../components';
import API from '../../../services/data.service';
import {useForm} from '../../../utils/useForm';
import validate from '../../../utils/validate/validateBook';

const initialDatabook = {
    idBuku: '',
    barcodeBuku: '',
    judulBuku: '',
    hargaBuku: '',
    beratBuku: '',
    isbnBuku: '',
    penerbitBuku: '',
    kategoriBuku: '',
    statusBuku: '',
    halamanBuku: '',
    ukuranBuku: '',
    coverBuku: '',
    tahunBuku: '',
    deskripsiBuku: '',
}
export default function DataBookModal({handleShow, data, edit, title}) {
    const {customChange, values} = useForm(data ? data : initialDatabook);
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const dispatch = useDispatch();
    
    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setSubmiting(true);
    };

    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                edit ? await API.editBook(values) : await API.addBook(values);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload();
            } catch (err) {
                dispatch({type: 'SET_LOADING', value: false});
                console.error(err);
                await Alert.error();
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [errors, submiting, dispatch, edit, handleShow, values])

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.formContainer}>
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='ID Buku' type='text' name="idBuku" value={values.idBuku} onChange={(e) => customChange(e)} error={errors.idBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Barcode' type='text' name="barcodeBuku" value={values.barcodeBuku} onChange={(e) => customChange(e)} error={errors.barcodeBuku} />
                                        </div>
                                    </div>
                                    <Input label='Judul Buku' type='text' name="judulBuku" value={values.judulBuku} onChange={(e) => customChange(e)} error={errors.judulBuku} />
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Harga' type='text' name="hargaBuku" value={values.hargaBuku} onChange={(e) => customChange(e)} error={errors.hargaBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Berat Buku' type='text' name="beratBuku" value={values.beratBuku} onChange={(e) => customChange(e)} error={errors.beratBuku} />
                                        </div>
                                    </div>
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='ISBN' type='text' name="isbnBuku" value={values.isbnBuku} onChange={(e) => customChange(e)} error={errors.isbnBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Penerbit' type='text' name="penerbitBuku" value={values.penerbitBuku} onChange={(e) => customChange(e)} error={errors.penerbitBuku} />
                                        </div>
                                    </div>
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Kategori' type='text' name="kategoriBuku" value={values.kategoriBuku} onChange={(e) => customChange(e)} error={errors.kategoriBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Status' type='text' name="statusBuku" value={values.statusBuku} onChange={(e) => customChange(e)} error={errors.statusBuku} />
                                        </div>
                                    </div>
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Halaman' type='text' name="halamanBuku" value={values.halamanBuku} onChange={(e) => customChange(e)} error={errors.halamanBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Ukuran' type='text' name="ukuranBuku" value={values.ukuranBuku} onChange={(e) => customChange(e)} error={errors.ukuranBuku} />
                                        </div>
                                    </div>
                                    <div className={styles.cDoubleRows}>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Jenis Cover' type='text' name="coverBuku" value={values.coverBuku} onChange={(e) => customChange(e)} error={errors.coverBuku} />
                                        </div>
                                        <div className={styles.cHalfRows}>
                                            <Input label='Tahun Terbit' type='number' name="tahunBuku" value={values.tahunBuku} onChange={(e) => customChange(e)} error={errors.tahunBuku} />
                                        </div>
                                    </div>
                                    <Input label='Deskripsi' type='text-editor' value={values.deskripsiBuku} onChange={(e, editor) => customChange(e, editor, 'deskripsiBuku')} error={errors.deskripsiBuku} />
                                </div>
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-4',
    cDoubleRows: 'flex space-x-4',
    cHalfRows: 'flex flex-col w-1/2',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0'
}
