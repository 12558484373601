import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchList = (passProps) => {
    const props = passProps.props;
    return (
        <div>
            <label className={styles.label}>{props.label}</label>
            <div className={styles.searchContainer}>
                <div className={styles.inputBox}>
                    <input className='w-full' type="text" placeholder="Search..." value={props.searchValue} onChange={(e) => props.onChange(e)} />
                    <div className={styles.searchData(props.isSearch, props.searchLoad)}>
                        <ul>
                            {props.searchLoad ? (  <div>Loading...</div>) :
                                (
                                    props.notFound ? <div>Not Found...</div> :
                                    <ListData props={props} />
                                )
                            }
                        </ul>
                    </div>
                </div>
                {props.error && <span className={styles.error}>{props.error}</span>}
                <div className='space-y-1 mt-2 mx-4'>
                    {props.listData &&
                        <TitleData props={props} />
                    }
                </div>
            </div>
        </div>
    )
}

const ListData = ({props}) => {
    if(props.showTitle === 'product'){
        return (
            props.searchData.map((item,idx) => {
                return(
                    <li key={idx} onClick={(e) => props.onClickItem(e, item)} className={styles.listData}>
                        {props.isPO ? item.namaPromo : item.judulBuku}
                    </li>
                )
            })
        )
    }
    return (
        props.searchData.map((item,idx) => {
            return(
                <li key={idx} onClick={(e) => props.onClickItem(e, item)} className={styles.listData}>{item.judulBuku}</li>
            )
        })
    )
}

const TitleData = ({props}) =>{
    if(props.showTitle === 'product'){
        return(
            props.listData.map((item, idx) => {
                return(
                    <div key={idx} className={styles.bukuContainer}>
                        <span>{item.nama}</span>
                        <button type='button' className={styles.btnBukuDel} title='Close' onClick={() => props.onRemoveList(idx)}>
                            <FontAwesomeIcon icon="times" size='lg' />
                        </button>
                    </div>
                )
            })
        )
    }
    
    return(
        props.listData.map((item, idx) => {
            return(
                <div key={idx} className={styles.bukuContainer}>
                    <span>{item.judulBuku}</span>
                    <button type='button' className={styles.btnBukuDel} title='Close' onClick={() => props.onRemoveList(idx)}>
                        <FontAwesomeIcon icon="times" size='lg' />
                    </button>
                </div>
            )
        })
    )
}

const styles = { 
    label: 'ml-1 text-gray-800 font-semibold',
    searchContainer: 'w-full p-4 bg-gray-200',
    inputBox: 'w-full container relative mx-auto',
    searchData: (show, load) => ((show ? 'block ' + (load ? 'animate-pulse' : 'animate-none') : 'hidden animate-none') 
    + ' block absolute z-50 bg-white text-gray-700 p-2 w-full list-none text-left rounded shadow-lg'),
    listData: 'cursor-pointer hover:bg-gray-200',
    error:'text-red-400 text-left text-sm',
    bukuContainer: 'w-full h-auto bg-white rounded-full cursor-pointer hover:bg-gray-50 px-4 py-1 flex justify-between items-center',
    btnBukuDel: 'focus:outline-none mr-2 text-red-500',
}

export default SearchList;


