import React from 'react';
import MenuList from './MenuList';
import { SidebarData } from './SidebarData';

export default function Sidebar() {
    return (
        <>
            <nav className={styles.nav}>
                <div className={styles.container}>
                    {
                        SidebarData.map((item, index) => { 
                            return <MenuList item={item} key={index} />; 
                        })
                    }
                </div>
            </nav>   
        </>
    )
}

const styles = {
    nav: 'flex hidden md:fixed md:flex m-0 md:w-64 md:flex-no-wrap md:overflow-y-auto md:left-0 md:top-14 md:bottom-0 bg-white md:shadow',
    container: 'md:flex-col md:min-h-full px-0 flex items-center w-full mx-auto',
}
