import React, { useState } from 'react';
import { Link } from "react-router-dom";

export default function MenuList({item}) {
    const [collapse, setCollapse] = useState(false);
    const showCollapse = () => setCollapse(!collapse);

    return (
        <>
            <Link to={item.path} className={styles.menu} onClick={item.subNav && showCollapse}>
                <div className='space-x-3'>
                    {item.icon}
                    <span className={styles.label}>{item.title}</span>
                </div>
                <div>
                    {item.subNav && collapse ?
                        item.iconOpened : item.subNav ?
                        item.iconClosed : null
                    }
                </div>
            </Link>
            {collapse && 
                item.subNav.map((item, idx) => {
                    return (
                        <Link to={item.path} className={styles.subList} key={idx}>
                            <span>{item.title}</span> 
                        </Link>
                    )
                })
            }
        </>
    )
}

const styles ={
    menu: 'md:min-w-full flex p-4 h-50 items-center hover:bg-gray-100 opacity-75 justify-between border-l-4 hover:border-red-600',
    label: 'cursor-default',
    subList: 'flex h-10 pl-8 items-center hover:bg-gray-100 md:min-w-full'
}