import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HeaderContent(props) {
    return (
        <>
           <div className={styles.cHeader}>
                <span className={styles.cTitle}>{props.title}</span>
                <div className='flex items-center space-x-4 mx-4'>
                    {props.handleLimits &&
                        props.type !== 'product' &&
                        <div className='flex items-center space-x-2 text-sm'>
                            <span >Jumlah Data : </span>
                            <select className={styles.limits} name='limits' onChange={(e) => props.handleLimits(e)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={0}>ALL</option>
                            </select>
                        </div>
                    }
                    {
                        props.type === 'product' ?
                        props.onChangeSearch &&
                        <select className={styles.search} name='offset' onChange={(e) => props.onChangeSearch(e.target.value)}>
                            <option value=''>All</option>
                            <option value='best'>Best Seller</option>
                            <option value='new'>New Product</option>
                            <option value='po'>Pre Order</option>
                        </select>
                        :
                        props.onSearchSubmit &&
                        <form onSubmit={(e) => props.onSearchSubmit(e)}>
                            <input 
                                className={styles.search} 
                                type="text" 
                                placeholder="Search..." 
                                value={props.searchValue} 
                                onChange={(e) => props.onChangetext(e.target.value)} 
                            />
                        </form>
                    }
                    {
                        props.type === 'keep' ?
                        props.showModal &&
                        <button className={styles.btnDelete} onClick={props.showModal}>
                            <FontAwesomeIcon icon='trash' />
                            <span>Hapus</span>
                        </button>
                        :
                        props.showModal &&
                        <button className={styles.btnAdd} onClick={props.showModal}>
                            <FontAwesomeIcon icon='plus-circle' />
                            <span>Tambah</span>
                        </button>
                    }
                </div>
            </div> 
        </>
    )
}

const styles ={
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    limits: 'py-0 px-0.5',
    search: 'py-0',
    btnAdd: 'bg-green-500 active:bg-green-700 text-white flex items-center p-1 space-x-2 focus:outline-none rounded',
    btnDelete: 'bg-red-500 active:bg-red-700 w-24 text-white flex justify-center items-center p-1 space-x-2 focus:outline-none rounded'
}
