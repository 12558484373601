import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import API from '../../../services/data.service';
import CustModal from './CustModal';
import Swal from 'sweetalert2';
import Alert from '../../../components/Alerts';
import FooterContent from '../../../components/Footers/FooterContent';
import HeaderContent from '../../../components/Headers/HeaderContent';
import {usePagination} from '../../../utils/useForm';

export default function Customers() {
    const [searchValue, setSearchValue] = useState('');
    const [paramsGet, setParamsGet] = useState({nama: searchValue, offset: 1, limit: 10});
    const [dataCount, setDataCount] = useState(0);
    const [listCust, setListCust] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [custDetail, setCustDetail] = useState(null);
    const {handlePage, handleLimits} = usePagination(paramsGet, setParamsGet);
    const dispatch = useDispatch();

    ///// Search /////
    const onSearchSubmit = async (e) => {
        e.preventDefault();
        setParamsGet({
            ...paramsGet,
            nama: searchValue
        })
        setSearchValue('');
    }

    //////////// Content ///////////////
    const parseCustType = (params) => {
        if (typeof params === 'string' || params instanceof String){
            return params === 'Customer' ? 0 : 1;
        }
        else{
            return params === 0 ? 'Customer' : 'Reseler';
        }
    }

    const handleEditClick = async (id) => {
        dispatch({type: 'SET_LOADING', value: true});
        const data = await API.getCustDetail(id);
        setCustDetail(data.data);
        dispatch({type: 'SET_LOADING', value: false});
        setShowEdit(true);
    }

    const handleResetClick = (id) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Apakah anda yakin akan mereset user dengan id ${id}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, lakukan reset!'
          }).then((result) => {
            if (result.isConfirmed) {
                postData(id);
            }
          })
    }

    const postData = async (id) => {
        try {
            dispatch({type: 'SET_LOADING', value: true});
            await API.resetPassCust(id);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            window.location.reload();
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    }

    useEffect(() => {
        const fetchListCust = async (paramsGet) => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getListCust(paramsGet);
                setDataCount(data.count);
                setListCust(data.data);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                if(paramsGet.nama !== ''){
                    Alert.notFound();
                }else{
                    Alert.error();
                }
            }
        }
        fetchListCust(paramsGet);
    }, [paramsGet, dispatch])

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Data Cutomers</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Data / </span>
                    <strong>Customer</strong>
                </div>
                <div className={styles.content}>
                    <HeaderContent 
                        title='Data Customer'
                        searchValue={searchValue}
                        handleLimits={(e) => handleLimits(e)}
                        onChangetext={(value) => setSearchValue(value)}
                        onSearchSubmit={(value) => onSearchSubmit(value)}
                    />
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Id Customer</th>
                                    <th>User ID</th>
                                    <th>Alamat</th>
                                    <th>Nama</th>
                                    <th>Jenis</th>
                                    <th>No. Telepon</th>
                                    <th>Reset</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                            {listCust &&
                                listCust.map((item, idx) => {
                                    return (
                                        <tr key={idx} className={styles.row}>
                                            <td>{(idx + 1)}</td>
                                            <td>{item.idCust}</td>
                                            <td className='text-left px-3'>{item.userName}</td>
                                            <td className='text-left px-3'>{item.alamat !== '-' ? `${item.alamat.slice(0, 30)}...` : item.alamat}</td>
                                            <td className='text-left px-3'>{item.nama}</td>
                                            <td className='px-3'>{parseCustType(item.jenisCust)}</td>
                                            <td className='px-3'>{item.telephone}</td>
                                            <td className='px-3'>
                                                <button className={styles.btnReset} title='Reset' onClick={() => handleResetClick(item.idCust)} >
                                                    <span>Reset Password</span>
                                                </button>
                                            </td>
                                            <td className=''>
                                                <button className={styles.btnEdit} title='Edit' onClick={() => handleEditClick(item.idCust)}>
                                                    <FontAwesomeIcon icon='edit' />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) 
                            }
                            </tbody>
                        </table>
                    </div>
                    <FooterContent page={paramsGet.offset} jumlahData={dataCount} limit={paramsGet.limit} setPage={(e, type) => handlePage(e, type)}  />
                </div>
            </div>
            {showEdit && <CustModal title='Tambah Data Customer' handleShow={() => setShowEdit(false)} data={custDetail} />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    btnAdd: 'bg-green-500 active:bg-green-700 text-white mr-4 p-2 space-x-2 focus:outline-none rounded',
    tableContainer: 'py-4 px-6',
    table: 'table-auto w-full text-center',
    row: ' items-center border-t cursor-pointer hover:bg-gray-100',
    btnReset:'bg-blue-400 hover:bg-blue-600 px-2 text-white py-0.5 focus:outline-none rounded text-sm',
    btnEdit: 'p-2 bg-yellow-500 active:bg-yellow-700 focus:outline-none rounded text-sm',
}