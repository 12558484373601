import axios from 'axios';
import cookie from 'react-cookies';
import authHeader from './auth-header';
// production
const BASE_URL = 'https://apiadmin.aqwam.com/api/';
// develoment
// const BASE_URL = 'https://apiadmin.aqwam.biz/api/';

const Post = (path, params) => {
    const api = axios.create({baseURL: BASE_URL});
    const promise = new Promise ((resolve, reject) =>{
        api.post(`${BASE_URL}${path}`, params)
        .then((result) => {
            resolve(result.data);
        }, (err) => {
            // console.log('data = ', err.response.data.data);
            reject( err.response.data.data);
        })
    })

    return promise;
}

const Logout = (params) => {
    const api = axios.create({baseURL: BASE_URL});
    const promise = new Promise ((resolve, reject) =>{
        api.post(`${BASE_URL}ceklogout`, params, { headers: authHeader() })
        .then((result) => {
            clearCookies();
            resolve(result.data);
        }, (err) => {
            // console.log('data = ', err.response.data.data);
            reject( err.response.data.data);
        })
    })

    return promise;
}

const clearCookies = () => {
    cookie.remove('nama', { path: '/' });
    cookie.remove('id', { path: '/' });
    cookie.remove('access_token', { path: '/' });
    cookie.remove('jabatan', { path: '/' });
    cookie.remove('kodeAdmin', { path: '/' });
}

const login = (params) => Post('ceklogin', params);

const AuthService = {
    login,
    Logout,
    clearCookies,
}

export default AuthService;