import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderContent from '../../../components/Headers/HeaderContent';
import FooterContent from '../../../components/Footers/FooterContent';
import API from '../../../services/data.service';
import Alert from '../../../components/Alerts';
import { useDispatch } from 'react-redux';
import {usePagination} from '../../../utils/useForm';
import KeepModals from './KeepModals';

export default function Keep() {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [paramsGet, setParamsGet] = useState({offset: 1, namaPromo: searchValue, limit: 10});
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const {handlePage, handleLimits} = usePagination(paramsGet, setParamsGet);
    const [showModals, setShowModals] = useState(false);

    //// Search //////
    const onSearchSubmit = (e) => {
        e.preventDefault();
        setParamsGet({
            ...paramsGet,
            namaPromo: searchValue
        })
        setSearchValue('');
    }

    useEffect(() => {
        const fetchData = async (paramsGet) => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getKeep(paramsGet);
                setDataList(data.data);
                setDataCount(data.count);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                if(paramsGet.judul !== ''){
                    Alert.notFound();
                }else{
                    Alert.error();
                }
            }
        }
        fetchData(paramsGet);
    }, [paramsGet, dispatch])

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Keep PO Reseller</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Data / </span>
                    <strong>Keep</strong>
                </div>
                <div className={styles.content}>
                    <HeaderContent 
                        title='Keep PO Reseller'
                        type= 'keep'
                        showModal={() => setShowModals(true)}
                        searchValue={searchValue}
                        handleLimits={(e) => handleLimits(e)}
                        onChangetext={(value) => setSearchValue(value)}
                        onSearchSubmit={(value) => onSearchSubmit(value)}
                    />  
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Promo</th>
                                    <th>Nama Admin</th>
                                    <th>Nama Customer</th>
                                    <th>Sold</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList &&
                                    dataList.map((item, idx) => {
                                        return(
                                            <tr key={idx} className={styles.row}>
                                                <td>{(idx + 1)}</td>
                                                <td className='text-left'>{item.namaPromo}</td>
                                                <td >{item.namaAdmin}</td>
                                                <td className='text-left'>{item.namaCust}</td>
                                                <td >{item.sold}</td>
                                                <td >{item.total}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <FooterContent page={paramsGet.offset} jumlahData={dataCount} limit={paramsGet.limit} setPage={(e, type) => handlePage(e, type)}  />
                </div>
            </div>
            {showModals && <KeepModals handleShow={() => setShowModals(false)} title='Hapus Keep PO Reseller' />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    cHeader: 'py-4 text-lg border-b flex justify-between items-center',
    cTitle: 'pl-8 border-l-4 border-red-600',
    btnAdd: 'bg-green-500 active:bg-green-700 text-white mr-4 p-2 space-x-2 focus:outline-none rounded',
    tableContainer: 'py-4 px-6',
    table: 'table-auto w-full text-center',
    row: ' items-center border-t cursor-pointer hover:bg-gray-100',
    btnShowStore: (active) => ((active ? 'bg-green-400 hover:bg-green-600' : 'bg-red-400 hover:bg-red-600') + ' px-2 text-white py-0.5 focus:outline-none rounded text-sm w-10'),
    btnEdit: 'p-2 bg-yellow-500 active:bg-yellow-700 focus:outline-none rounded text-sm',
    btnShowPicture: 'p-2 bg-green-500 active:bg-green-700 focus:outline-none rounded text-sm',
    btnShowGallery: 'p-2 bg-blue-500 active:bg-blue-700 focus:outline-none rounded text-sm',
    btnDelete: 'p-2 bg-red-500 active:bg-red-700 focus:outline-none rounded text-sm',
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 z-30 bg-gray-50'
}

