import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Alert from '../../../components/Alerts';
import HeaderContent from '../../../components/Headers/HeaderContent';
import API from '../../../services/data.service';
import Utils from '../../../utils';
import ProductModals from './ProductModals';
import EditProduct from './EditProduct';

export default function HomeProduct() {
    const [listProduct, setListProduct] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [dataDetail, setDataDetail] = useState(null);
    const [paramsGet, setParamsGet] = useState({type: '', offset: 1, limit: 10});
    const dispatch = useDispatch();
    
    ////// Search /////
    const handleSearchChange = (val) => {
        setParamsGet({
            ...paramsGet,
            type: val
        })
    }

    ///// Content
    const handleOnClick =(id) =>{
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: `Data akan dihapus!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Hapus data!'
          }).then((result) => {
            if (result.isConfirmed) {
              deleteProduct(id)
            }
          })
    }

    const handleEditClick = async (id, typePO, codePromo) => {
        dispatch({type: 'SET_LOADING', value: true});
        const data = await API.getDetailKeep(id);
        setDataDetail(data.data);
        dispatch({type: 'SET_LOADING', value: false});
        setShowEdit(true);
    }

    const deleteProduct = async (id)=>{
        dispatch({type: 'SET_LOADING', value: true});
        try {
            await API.deleteProduct(id);
            dispatch({type: 'SET_LOADING', value: false});
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            )
            window.location.reload(false);
        } catch (error) {
            dispatch({type: 'SET_LOADING', value: false});
            Alert.error();
        }
    }

    useEffect(() => {
        const fetchListProduct = async (paramsGet) => {
            dispatch({type: 'SET_LOADING', value: true});
            try {
                const data = await API.getListProduct(paramsGet);
                setListProduct(data.data);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (error) {
                dispatch({type: 'SET_LOADING', value: false});
                Alert.error();
            }
        }
        fetchListProduct(paramsGet);
    },[paramsGet, dispatch])

    // console.log('list = ', listProduct);

    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.title}>Data Home Product</h1>
                <div className={styles.currentPage}>
                    <FontAwesomeIcon icon='home' />
                    <span>/ Data / </span>
                    <strong>Home Product</strong>
                </div>
                <div className={styles.content}>
                    <HeaderContent 
                        type='product'
                        title='Data Home Product'  
                        showModal={() => setShowAddModal(true)}
                        onChangeSearch={(value) => handleSearchChange(value)}
                    />
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Judul Buku</th>
                                    <th>Kategori</th>
                                    <th>Tipe Pre Order</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                            {listProduct &&
                                listProduct.map((item, idx) => {
                                    return (
                                        <tr key={idx} className={styles.row}>
                                            <td>{(idx + 1)}</td>
                                            <td className='text-left'>{item.judulBuku}</td>
                                            <td>{Utils.formatPO(item.kategori)}</td>
                                            <td>{Utils.formatPO(item.typePO)}</td>
                                            <td className='w-20 text-white flex justify-between'>
                                                {item.kategori === 'po' ?
                                                    <button className={styles.btnEdit} title='Edit' onClick={() => handleEditClick(item.idProduct, item.typePO, item.codePromo)}>
                                                        <FontAwesomeIcon icon='edit' />
                                                    </button>
                                                    :
                                                    <div></div>
                                                }
                                                <button className={styles.btnDelete} title='Hapus' onClick={() => handleOnClick(item.idProduct)}>
                                                    <FontAwesomeIcon icon='trash' />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }) 
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showAddModal && <ProductModals title='Tambah Data Product' handleShow={() => setShowAddModal(false)} />}
            {showEdit && <EditProduct title='Edit Data Product' data={dataDetail} handleShow={() => setShowEdit(false)} />}
        </>
    )
}

const styles = {
    container: 'py-4 px-6',
    title: 'text-2xl text-gray-900 font-semibold',
    currentPage: 'flex items-center text-sm space-x-1 py-2',
    content: 'w-full bg-white h-auto my-3',
    tableContainer: 'py-4 px-6',
    table: 'table-auto w-full text-center',
    row: ' items-center border-t cursor-pointer hover:bg-gray-100',
    btnDelete: 'p-2 bg-red-500 active:bg-red-700 focus:outline-none rounded text-sm',
    btnEdit: 'p-2 bg-yellow-500 active:bg-yellow-700 focus:outline-none rounded text-sm',
}
