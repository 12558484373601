export default function validateLogin(values) {
    let errors = {};
    
    if(!values.username.trim()){
        errors.username = 'username required!'
    }

    //password
    if(!values.password){
        errors.password = 'password required!'
    } else if (values.password.length < 6){
        errors.password = 'Password > 6'
    }

    return errors;
}
