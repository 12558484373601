import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch} from 'react-redux';
import API from '../../../services/data.service';
import Alert from '../../../components/Alerts';
import Input from '../../../components/InputForm';
import validate from '../../../utils/validate/validateProduct';
import {useForm, SearchForm} from '../../../utils/useForm';

const initialProduct = {
    kategori : 'best',
    judulBuku: [],
    typePO: '0',
    jumlahKeep: ''
}

export default function ProductModals({title, data, handleShow}) {
    const {values, setValues} = useForm(data ? data : initialProduct);
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const [isPO, setIsPO] = useState(false);
    const [isNormal, setIsNormal] = useState(false);
    const {
        handleSeacrhChange, 
        searchValues, 
        searchLoad, 
        isSearch, 
        searchData, 
        notFound,
        setSearchValues,
        setSearch, 
    } = SearchForm('product', isPO);
    const kategori = [{value: 'best', label:'Best Seller'},{value:'new', label:'New Product'},{value:'po', label:'Pre Order'}];
    const typePO = [{value:0, label:'Normal'},{value:1, label:'Sold Out'},{value:2, label:'Coming Soon'},{value:3, label:'Promo Berakhir'}];
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors(validate(values, isPO))
        setSubmiting(true);
    }

    //form change
    const handleOnChange = (e) => {
        if(e.target.name === 'kategori'){
            handleRemoveBook(0);
            setSearchValues('');
        }
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    const handleSearchClick = (id, nama) => {
        setValues({
            ...values,
            judulBuku: [{id: id, nama: nama}]
        })
        setSearchValues('');
        setSearch(false);
    }

    const handleRemoveBook = (idx) => {
        values.judulBuku.splice(idx, 1);
        setValues({
            ...values,
            buku: [values.listBuku]
        })
    }

    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                await API.addProduct(values);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload(false);
            } catch (err) {
                console.error(err);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.error();
            }
        }
        if(values.kategori === 'po' ? setIsPO(true) : setIsPO(false));
        if(values.typePO === '0' ? setIsNormal(true) : setIsNormal(false));
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [values.kategori, handleShow, values.typePO, errors, submiting, dispatch, values])

    return (
        <>
             <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.formContainer}>
                                    <Input type='select' label='Kategori' name='kategori' value={values.kategori} onChange={handleOnChange} data={kategori} />
                                    <Input 
                                        type='search-list'
                                        label={isPO ? 'Nama Promo' : 'Judul Buku'}
                                        searchValue={searchValues}
                                        onChange={(e) => handleSeacrhChange(e)}
                                        isSearch={isSearch}
                                        searchLoad={searchLoad}
                                        notFound={notFound}
                                        searchData={searchData}
                                        onClickItem={(e, item) => isPO ? handleSearchClick(item.codePromo, item.namaPromo) : handleSearchClick(item.idBuku, item.judulBuku)}
                                        showTitle='product'
                                        isPO={isPO}
                                        listData={values.judulBuku}
                                        onRemoveList={(idx) => handleRemoveBook(idx)}
                                        error={errors.judulBuku}
                                    />
                                    {isPO &&
                                        <>
                                            <Input type='select' label='Tipe Pre Order' name='typePO' value={values.typePO} onChange={handleOnChange} data={typePO} />
                                            {isNormal && 
                                                <Input type='Number' label='Jumlah Keep' name='jumlahKeep' value={values.jumlahKeep} onChange={handleOnChange} error={errors.jumlahKeep} />}
                                        </> 
                                    }
                                </div>
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-4',
    cDoubleRows: 'flex space-x-4',
    cHalfRows: 'flex flex-col w-1/2',
    searchData: (show, load) => ((show ? 'block ' + (load ? 'animate-pulse' : 'animate-none') : 'hidden animate-none') 
    + ' block absolute container mx-auto z-50 bg-white text-gray-700 p-2 w-10/12 list-none text-left rounded shadow-lg'),
    listData: 'cursor-pointer hover:bg-gray-200',
    bukuContainer: 'w-full h-auto bg-white rounded-full cursor-pointer hover:bg-gray-200 px-2 py-1 flex justify-between items-center',
    btnBukuDel: 'focus:outline-none mr-2 text-red-500',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0'
}
