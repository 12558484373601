import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function FooterContent({page, jumlahData, limit, setPage}) {
    const pageDevide = jumlahData / limit;
    const pageOf = Math.floor(pageDevide) - pageDevide > 0 ? pageDevide : Math.floor(pageDevide)+1; 
    const labelPage = `Page ${page} of ${pageOf}`;
    return (
        <>
            <div className={styles.cFooter}>
                {(page > 1) &&
                    <button className={styles.btnPagination} title='Previous' onClick={(e) => setPage(e, -1)} >
                        <FontAwesomeIcon icon='angle-left' />
                        <span>Prev</span>
                    </button>
                }
                {page === 1 && <div></div>}
                {limit !== '0' && <span>{labelPage}</span>}
                {((jumlahData - (page * limit)) > 0) && (
                    limit > 0 &&
                    <button className={styles.btnPagination} title='Next' onClick={(e) => setPage(e, 1)} >
                        <span>Next</span>
                        <FontAwesomeIcon icon='angle-right' />
                    </button>
                )}
                {page === pageOf && <div></div>}
            </div>
        </>
    )
}

const styles ={
    cFooter: 'py-4 px-6 text-lg border-t justify-between flex items-center',
    btnPagination: ' bg-blue-400 hover:bg-blue-600 px-2 space-x-2 flex items-center text-white py-1 text-center focus:outline-none rounded text-sm',
}
