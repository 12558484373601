import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {HashRouter, Route, Switch } from "react-router-dom";
import './App.css';
import {Loading, ProtectedRoute} from './components';
import Auth from './layout/Auth';
import Home from './layout/Home';
import NotFound from './layout/NotFound';
import store from './redux/store';

const MainApp = () => {
  const stateGlobal = useSelector(state => state);
  const token = cookie.load('access_token');
  const dispatch = useDispatch();

  useEffect(() => {
    if(!token){
      dispatch({type: 'SET_LOGIN', value: false});
    }
  }, [token, dispatch])

  return (
    <>
      {stateGlobal.loading && <Loading />}
      <HashRouter>
      <Switch>
        {/* route layout */}
        <Route path='/login' component={Auth} />
        <ProtectedRoute login={stateGlobal.login} path='/' component={Home} />
        <Route component={NotFound} />
        {/* <ProtectedRoute login={true} path='/dashboard' component={Home} /> */}
        {/* redirect for first page */}
        {/* <Redirect exact from="*" to="/" /> */}
      </Switch>
      </HashRouter>
    </>
  );
}

function App() {
  return (
    <>
      <Provider store={store}>
        <MainApp />
      </Provider>
    </>
  );
}

export default App;
