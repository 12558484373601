export default function validateCust(values) {
    let errors = {};
    
    if(!values.idCust.trim()){
        errors.idCust = 'Id pelanggan wajib diisi!'
    }
    
    if(!values.nama.trim()){
        errors.nama = 'Nama pelanggan wajib diisi!'
    }
    
    if(!values.alamat.trim()){
        errors.alamat = 'Alamat wajib diisi!'
    }
    
    if(!values.telephone.trim()){
        errors.telephone = 'No telepon wajib diisi!'
    }

    return errors;
}
