import React from 'react'

export default function NotFound({location}) {
    return (
        <div className='relative m-auto'>
            <div className='h-screen p-8'>
                <h1>No match found for <code>{location.pathname}</code></h1>
            </div>
        </div>
    )
}
