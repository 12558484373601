export default function validatePromo(values) {
    let errors = {};
    
    if(!values.namaPromo.trim()){
        errors.namaPromo = 'Nama promo wajib diisi!'
    }
    
    if(!values.hargaJadi.trim()){
        errors.hargaJadi = 'Harga promo wajib diisi!'
    }
    
    if(!values.tanggalCetak.trim()){
        errors.tanggalCetak = 'Tanggal Cetak wajib diisi!'
    }
    
    if(!values.deskripsi.trim()){
        errors.deskripsi = 'Deskripsi wajib diisi!'
    }
    
    if(values.listBuku.length === 0){
        errors.listBuku = 'List buku promo wajib diisi!'
    }

    return errors;
}
