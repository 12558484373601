import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, login, ...rest }) => {
  return (
    <Route {...rest} render={
      props => {
        if (login) {
          // return <Component {...rest} {...props} />
          return <Component />
        } else {
          return <Redirect to='/login' />
        }
      }
    } />
  )
}

export default ProtectedRoute;