import cookie from 'react-cookies';

export default function authHeader() {
    const accessToken = cookie.load('access_token');
  
    if (accessToken) {
      return { Authorization: 'Bearer ' + accessToken };
    } else {
      return {};
    }
}