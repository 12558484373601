import React, {useState, createRef} from 'react';
import { createPopper } from '@popperjs/core';
import { ImgNullPhoto } from '../../assets/img';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from 'react-redux';
import AuthService from '../../services/auth.service';

export default function UserDropdown({size, icon}) {
    const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
    const btnDropdownRef = createRef();
    const popoverDropdownRef = createRef();
    const dispatch = useDispatch();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: 'bottom-start',
        });
        setDropdownPopoverShow(true);
    }
    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    }

    const handleOnClick = (e) => {
        e.preventDefault();
        dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
    }

    const handleLogout = async (e) => {
        e.preventDefault();
        await AuthService.Logout();
        dispatch({type: 'SET_LOGIN', value: false});
    }

    return (
        <>
            <div className={styles.container}>
                <a href='#test' className={styles.profil} ref={btnDropdownRef} onClick={handleOnClick}>
                    <img alt='profil' src={ImgNullPhoto} className={styles.image(size)} />
                    {icon && <FontAwesomeIcon icon='angle-down' />}
                </a>
            </div>
            <div ref={popoverDropdownRef} className={styles.popOver(dropdownPopoverShow)}>
                <h6 className={styles.menu}>Account</h6>
                <a href='#test' className={styles.menu}>
                    <FontAwesomeIcon icon="user" />
                    <span>Profile</span>
                </a>
                <hr className='my-4 min-w-full' />
                <button type='button' title='logout' className={styles.btnLogout} onClick={handleLogout} >
                    <FontAwesomeIcon icon="sign-out-alt" />
                    <span>Logout</span>
                </button>
            </div>   
        </>
    )
}

const styles = {
    container: 'flex items-center',
    profil: 'flex items-center space-x-1 text-gray-200',
    image: (size) => ((size !== undefined ? size : 'w-12') + ' rounded-full border-none shadow-lg'),
    popOver: (show) => ((show ? 'block' : 'hidden') + ' bg-white py-2 list-none rounded shadow-lg w-48 min-w-64 z-10'),
    menu: 'text-sm py-2 px-4 block items-center w-full text-gray-700 space-x-3',
    btnLogout: 'text-sm py-2 px-4 block items-center w-full text-red-700 space-x-3 hover:bg-gray-200 focus:outline-none'
}