import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarData = [
    {
        title: 'Master Data',
        path: '#',
        icon: <FontAwesomeIcon icon='database' />,
        iconOpened: <FontAwesomeIcon icon='chevron-up' />,
        iconClosed:  <FontAwesomeIcon icon='chevron-down' />,
        subNav: [
            {
                title: 'Data Buku',
                path: '/data/buku',
            },
            {
                title: 'Data Customer',
                path: '/data/customer',
            },
            {
                title: 'Data Buku Promo',
                path: '/data/promo',
            },
            {
                title: 'Data Home Product',
                path: '/data/homeProduct',
            },
            {
                title: 'Data Kategori Buku',
                path: '/data/categoryBook',
            },
            {
                title: 'Keep PO Reseller',
                path: '/data/keep',
            },
            {
                title: 'Data Lokasi',
                path: '/data/employer',
            },
            {
                title: 'Data Ulasan',
                path: '/data/employer',
            },
            {
                title: 'Data Berlangganan',
                path: '/data/employer',
            },
        ]
    },
    {
        title: 'Utility Store',
        path: '#',
        icon: <FontAwesomeIcon icon='cogs' />,
        iconOpened: <FontAwesomeIcon icon='chevron-up' />,
        iconClosed:  <FontAwesomeIcon icon='chevron-down' />,
        subNav: [
            {
                title: 'Data Slide Banner',
                path: '/utility/banner',
            },
            {
                title: 'Data Review Gambar',
                path: '/data/discount',
            },
        ]
    },
    {
        title: 'About Us',
        path: '#',
        icon: <FontAwesomeIcon icon='question-circle' />,
        iconOpened: <FontAwesomeIcon icon='chevron-up' />,
        iconClosed:  <FontAwesomeIcon icon='chevron-down' />,
        subNav: [
            {
                title: 'Tentang Kami',
                path: '/about/about',
            },
        ]
    },
];
