import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '../../../components/Alerts';
import Input from '../../../components/InputForm';
import API from '../../../services/data.service';
import Utils from '../../../utils';
import {useForm, SearchForm} from '../../../utils/useForm';
import validate from '../../../utils/validate/validatePromo';


const initialPromo = {
    namaPromo: '',
    jenisPO: 0,
    hargaJadi: '',
    tanggalCetak: '',
    deskripsi: '',
    listBuku: []
}

export default function PromoModal({title, handleShow, edit, data, reset}) {
    const {customChange, values, setValues} = useForm(data ? data : initialPromo);
    const jenisPO = [{value: 1, label: 'Yes'}, {value: 0, label: 'No'}];
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const {
        handleSeacrhChange, 
        searchValues, 
        searchLoad, 
        isSearch, 
        searchData, 
        notFound,
        setSearchValues,
        setSearch, 
    } = SearchForm('book');

    ////////Search ////////////
    const handleSearchClick = (id, judul) => {
        let valid = true;
        values.listBuku.forEach(val => {
            if(val.idBuku === id){
                valid = false;
            }
        });
        if(valid){
            setValues({
                ...values,
                listBuku: [...values.listBuku, {idBuku:id, judulBuku: judul}]
            });
        }
        setSearchValues('');
        setSearch(false);
    }

    const removeSelected = (idx) => {
        values.listBuku.splice(idx, 1);
        setValues({
            ...values,
            buku: [...values.listBuku]
        })
    }

    ////// submit data /////////
    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setSubmiting(true);
    };

    useEffect(() => {
        const postData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                edit ? API.editPromo(values) : await API.addPromo(values);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.success();
                handleShow();
                window.location.reload();
            } catch (err) {
                console.error(err);
                dispatch({type: 'SET_LOADING', value: false});
                await Alert.error();
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(false);
            postData();
        }
    }, [errors, submiting, dispatch, edit, handleShow, values])

    return (
        <>
           <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.formContainer}>
                                    {!isSearch && 
                                        <div className='space-y-3'>
                                            <Input label='Nama Promo' type='text' name="namaPromo" value={values.namaPromo} onChange={customChange} error={errors.namaPromo} />
                                            <Input label='Pre Order' type='select' data={jenisPO} name='jenisPO' value={values.jenisPO} onChange={customChange} error={errors.jenisPO} />
                                            <div className={styles.cDoubleRows}>
                                                <div className={styles.cHalfRows}>
                                                    <Input label='Harga Promo' type='number' name="hargaJadi" value={values.hargaJadi} onChange={customChange} error={errors.hargaJadi} />
                                                </div>
                                                <div className={styles.cHalfRows}>
                                                    <Input label='Tanggal Cetak' type='date' name="tanggalCetak" value={Utils.formatDate(values.tanggalCetak, 'YYYYmmdd')} onChange={customChange} error={errors.tanggalCetak} />
                                                </div>
                                            </div>
                                            <Input label='Deskripsi' type='text-editor' value={values.deskripsi} onChange={(event, editor) => customChange(event, editor, 'deskripsi')} error={errors.deskripsi} />
                                        </div>
                                    }
                                    <Input 
                                        type='search-list'
                                        label='Buku'
                                        searchValue={searchValues}
                                        onChange={(e) => handleSeacrhChange(e)}
                                        isSearch={isSearch}
                                        searchLoad={searchLoad}
                                        notFound={notFound}
                                        searchData={searchData}
                                        onClickItem={(e, item) => handleSearchClick(item.idBuku, item.judulBuku)}
                                        listData={values.listBuku}
                                        onRemoveList={(idx) => removeSelected(idx)}
                                        error={errors.listBuku}
                                    />
                                </div>
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                </div>
                            </form>
                            <div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-3 h-auto p-4',
    cDoubleRows: 'flex space-x-4',
    cHalfRows: 'flex flex-col w-1/2',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0',
}

