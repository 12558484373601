import React from 'react';

export default function SelectInput(props) {
    const data = props.props.data
    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>{props.props.label}</label>
                <select name={props.props.name} value={props.props.value} onChange={(e) => props.props.onChange(e)}>
                    {data && data.map((data, idx) => {
                        return (
                            <option key={idx} value={data.value}>{data.label}</option>
                        )
                    })}
                </select>
                {props.props.error && <span className={styles.error}>{props.props.error}</span>}
            </div>
        </>
    )
}

const styles = {
    container: 'flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    error:'text-red-400 text-left text-sm'
}
