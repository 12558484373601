import React from 'react';
import TextEditor from './TextEditor';
import SelectInput from './SelectInput';
import SearchList from './SearchList';
import SendFiles from './SendFiles';
import SelectMultiple from './SelectMultiple';

export default function Input(props) {
    switch(props.type){
        case 'text-editor':
            return(
                <TextEditor props={props} />
            )
        case 'select':
            return(
                <SelectInput props={props} />
            )
        case 'search-list':
            return(
                <SearchList props={props} />
            )
        case 'files':
            return(
                <SendFiles props={props} />
            )
        case 'multiple-select':
            return(
                <SelectMultiple props={props} />
            )
        default:
            return (
                <>
                    <div className={styles.container}>
                        <label className={styles.label}>{props.label}</label>
                        <input 
                            className={props.className}
                            type={props.type} 
                            placeholder={props.placeholder}
                            name={props.name} 
                            value={props.value}
                            onChange={(e) => props.onChange(e)}
                            readOnly={props.readOnly}
                        />
                        {props.error && <span className={styles.error}>{props.error}</span>}
                    </div>
                </>
            )
    }
}

const styles = {
    container: 'flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    error:'text-red-400 text-left text-sm'
}
