import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from '../../../components';
import API from '../../../services/data.service';
import { useForm } from '../../../utils/useForm';


export default function KeepModals({handleShow, data, id, title}) {
    // const {values, setValues} = useForm({codePromo: '', namaPromo: ''});
    const {handleChange, values} = useForm({codePromo: ''});
    const [dataPromo, setDataPromo] = useState(null);
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch({type: 'SET_LOADING', value: true});
            await API.delpromokeep(values);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.success();
            handleShow();
            window.location.reload(false);
        } catch (err) {
            console.error(err);
            dispatch({type: 'SET_LOADING', value: false});
            await Alert.error();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                const data = await API.getpromokeep();
                setDataPromo(data.data);
                dispatch({type: 'SET_LOADING', value: false});
            } catch (err) {
                dispatch({type: 'SET_LOADING', value: false});
                console.error(err);
                Alert.error();
            }
        }
        fetchData();
    }, [dispatch])

    return (
        <>
            <div className={styles.modalWrapper}>
                <div className={styles.modalBackdrops}>
                    <div className={styles.modalBox}>
                        <div className={styles.modalHeader}>
                            <div></div>
                            <h1>{title}</h1>
                            <button type='button' className={styles.btnClose} title='Close' onClick={() => handleShow()}>
                                <FontAwesomeIcon icon="times" size='lg' />
                            </button>
                        </div>
                        <div className={styles.modalContent}>
                            <form onSubmit={onSubmit}>
                                <div className={styles.formContainer}>
                                    <div className={styles.container}>
                                        <label className={styles.label}>Promo</label>
                                        <select value={values.codePromo} name='codePromo' onChange={(e) => handleChange(e)}>
                                            {dataPromo && dataPromo.map((data, idx) => {
                                                return (
                                                    <option key={idx} value={data.codePromo}>{data.namaPromo}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Simpan' type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles = { 
    modalWrapper: 'fixed top-0 bottom-0 left-0 right-0 z-10',
    modalBackdrops: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-800 flex bg-opacity-50 justify-center items-center',
    modalBox: 'relative bg-white w-3/4 h-5/6 rounded p-6 overflow-y-scroll',
    modalHeader: 'text-gray-700 flex items-center justify-between pb-3 border-b-2',
    btnClose: 'focus:outline-none',
    modalContent: 'w-full py-3 h-full',
    formContainer: 'flex flex-col bg-gray-100 space-y-2 h-auto p-4',
    container: 'flex flex-col',
    label: 'ml-1 text-gray-800 font-semibold',
    error:'text-red-400 text-left text-sm',
    btnSubmit: 'bg-green-500 px-4 mx-3 mt-4 active:bg-green-700 text-white ring-0 focus:ring-0'
}