import React from 'react';
import cookie from 'react-cookies';
import { ImgDashboard } from '../assets/img';

export default function Welcome() {
    const username = cookie.load('nama');
    return (
        <div className='relative m-auto'>
            <div className='h-full flex flex-col justify-center space-y-4 items-center pt-24'>
                <h1 className='text-gray-500 text-2xl'>Welcome Back {username}</h1>
                <img alt='profil' src={ImgDashboard} className='w-1/2' />  
            </div>
        </div>
    )
}
