import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ImgLogo } from '../assets/img';
import Input from '../components/InputForm';
import AuthService from '../services/auth.service';
import useForm from '../utils/useForm/useForm';
import validate from '../utils/validate/validateLogin';

export default function Auth() {
    const {handleChange, values} = useForm({username:'', password:''});
    const [errors, setErrors] = useState({});
    const [submiting, setSubmiting] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const setCookies = (name, value) => {
        const expires = new Date()
        expires.setDate(new Date().getTime() + 1*60*60*1000);

        return cookie.save(name, value, {path: '/', expires, secure: true});
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors(validate(values));
        setSubmiting(true);
    }

    useEffect(() => {
        const fetchLogin = async () => {
            try {
                dispatch({type: 'SET_LOADING', value: true});
                const data = await AuthService.login(values);
                const res = data.data.result;
                if(res.access_token){
                    setCookies('nama', res.nama);
                    setCookies('access_token', res.access_token);
                    setCookies('id', res.id);
                    setCookies('jabatan', res.jabatan);
                    setCookies('kodeAdmin', res.kodeAdmin);
                }
                dispatch({type: 'SET_LOGIN', value: true});
                history.push('/');
                dispatch({type: 'SET_LOADING', value: false});
            } catch (err) {
                console.error('error = ',err);
                setErrors({auth : err.status.description});
                dispatch({type: 'SET_LOADING', value: false});
            }
        }
        if(Object.keys(errors).length === 0 && submiting){
            setSubmiting(!submiting);
            fetchLogin();
        }
    }, [errors, submiting, dispatch, history, values])


    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.box}>
                    <div className={styles.logo}>
                        <img alt='logo' src={ImgLogo} className='h-14' />
                    </div>
                    <div className={styles.content}>
                        <h1 className={styles.title}>Login</h1>
                        <div className='mt-8' onSubmit={onSubmit}>
                            {errors.auth && <p className={styles.error}>{errors.auth}</p>}
                            <form className='space-y-4' >
                                <Input 
                                    type='text' 
                                    className={styles.input} 
                                    placeholder='username'
                                    name='username' 
                                    value={values.username} 
                                    onChange={(e) => handleChange(e)} 
                                    error={errors.username}
                                />
                                <Input 
                                    type='password' 
                                    className={styles.input} 
                                    placeholder='password' 
                                    name='password'
                                    value={values.password} 
                                    onChange={(e) => handleChange(e)} 
                                    error={errors.password}
                                />
                                <div className='flex justify-end'>
                                    <input className={styles.btnSubmit} value='Login' type="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const styles={
    wrapper: 'fixed top-0 bottom-0 left-0 right-0 bg-gray-50 flex justify-center items-center',
    box: 'relative bg-white w-1/4 h-auto rounded p-6 shadow-2xl',
    logo: 'flex fl justify-center',
    content: 'flex flex-col justify-center text-center mt-8',
    title: 'text-3xl font-normal text-gray-600',
    error:'text-red-400 text-sm',
    input: 'w-full p-2',
    btnSubmit: 'bg-red-500 px-4 mt-4 active:bg-red-700 text-white ring-0 focus:bg-red-200'
}
